import { Controller } from "stimulus"

export default class extends Controller {

  initialize(){
  }

  connect() {
    this.common()
  }

  disconnect(){
  }

  common(){
    //fix snackbar turbolinks
    if (!document.getElementById("snackbar-container")) {
      $("body").append("<div id='snackbar-container'></div>");
    }
  }
}
