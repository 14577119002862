window.App || (window.App = {});
window.jQuery = $;
window.$ = $;

require("@rails/ujs").start()
require("turbolinks").start()
require("bootstrap")
require("snackbarjs")

//stimulus stuff
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("controllers", true, /public/)
application.load(definitionsFromContext(context))

// Stylesheets
import '../stylesheets/public.scss';
